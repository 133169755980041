import React, { useCallback } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import { DocumentRoutes } from "@navigation/RouteEnums";
import { DocumentRoutesParamList } from "@navigation/NavigationRouteParameters";
import SectionList from "@src/components/document/review/SectionList";
import { useContentClient } from "@contexts/ContentClient.context";
import { useNonNullDocumentContext } from "@contexts/Document.context";
import { pipe } from "fp-ts/lib/function";
import { fold } from "fp-ts/lib/TaskEither";
import * as T from "fp-ts/lib/Task";
import * as IO from "fp-ts/lib/IO";
import { useIoBasedLoadingSetters } from "@contexts/Loading.context";
import { handleContentErrorIo } from "@utils/errorHandlers/contentErrorHandler";
import { Section } from "@src/model/documents/core";
import { useIoState } from "@src/utils/useIoState";
import NewSectionModal from "@src/components/document/review/NewSectionModal";
import { Text, TouchableOpacity, View } from "react-native";
import { WritingStyles as styles } from "@src/styles/Review.style";
import { DocumentID } from "@model/documents/core";
import { useLifecycleClient } from "@contexts/LifecycleClient.context";
import { LifecycleError } from "@model/clients/LifecycleApi";
import { handleLifecycleErrorIo } from "@utils/errorHandlers/lifecycleErrorHandler";
import * as TE from "fp-ts/lib/TaskEither";
import { MaterialIcons } from "@expo/vector-icons";

type ReviewProps = {
  navigation: StackNavigationProp<
    DocumentRoutesParamList,
    typeof DocumentRoutes.REVIEW
  >;
};

const ReviewPage: React.FC<ReviewProps> = ({ navigation }) => {
  const { contentClient } = useContentClient();
  const {
    document: userDocument,
    activeSectionID,
    appendSectionIo,
  } = useNonNullDocumentContext();
  const { triggerLoadingState, triggerSuccessState, triggerErrorState } =
    useIoBasedLoadingSetters();
  const { lifecycleClient } = useLifecycleClient();

  const [isModalVisible, setIsModalVisible] = useIoState(false);
  const [newSectionTitle, setNewSectionTitle] = useIoState("");
  const [sectionTitleError, setSectionTitleError] = useIoState<string | null>(
    null
  );

  const navigateToPromptIo =
    (probingQuestions: string): IO.IO<void> =>
    () =>
      navigation.navigate(DocumentRoutes.PROMPT, {
        probingQuestion: probingQuestions,
      });

  const navigateToRefineIo: IO.IO<void> = () =>
    navigation.navigate(DocumentRoutes.REFINE);

  const navigateToFinalEditsIo: IO.IO<void> = () =>
    navigation.navigate(DocumentRoutes.FINAL_EDITS);

  const handleDraft: T.Task<void> = pipe(
    T.fromIO(triggerLoadingState),
    T.chain(() =>
      pipe(
        contentClient.generateSectionQuestions(userDocument.id, {
          section_id: activeSectionID!,
        }),
        fold(
          (error) => T.fromIO(handleContentErrorIo(error, triggerErrorState)),
          (response) =>
            T.fromIO(
              pipe(
                navigateToPromptIo(response.probing_questions),
                IO.flatMap(() => triggerSuccessState)
              )
            )
        )
      )
    )
  );

  const navigateToReviewIo: T.Task<void> = T.fromIO(() =>
    navigation.navigate(DocumentRoutes.REVIEW)
  );

  const handleSuccessIo = (newSection: Section): IO.IO<void> =>
    pipe(
      appendSectionIo(newSection), // Update section
      IO.flatMap(() => triggerSuccessState), // Set success after navigating
      IO.flatMap(() => navigateToReviewIo) // Navigate to review page
    );

  const handleAddNewSection = (sectionTitle: string): T.Task<void> => {
    return pipe(
      T.fromIO(triggerLoadingState), // Set loading state
      T.flatMap(() =>
        pipe(
          contentClient.appendSection(userDocument.id, {
            section_title: sectionTitle,
          }),
          fold(
            (error) => T.fromIO(handleContentErrorIo(error, triggerErrorState)), // Handle error case with io
            (response) => T.fromIO(handleSuccessIo(response.new_section)) // Handle success with io
          )
        )
      )
    );
  };

  const openModal: IO.IO<void> = pipe(
    triggerLoadingState,
    IO.chain(() => setIsModalVisible(true)),
    IO.chain(() => setNewSectionTitle("")),
    IO.chain(() => setSectionTitleError(null)),
    IO.chain(() => triggerSuccessState)
  );

  const closeModal: IO.IO<void> = pipe(
    triggerLoadingState,
    IO.chain(() => setIsModalVisible(false)), // Close the modal
    IO.chain(() => setSectionTitleError(null)),
    IO.chain(() => triggerSuccessState)
  );

  const handleSubmitNewSection: IO.IO<void> = pipe(
    T.fromIO(() => newSectionTitle.trim() === ""),
    T.chain((isEmpty) =>
      isEmpty
        ? T.fromIO(setSectionTitleError("Section title is required"))
        : pipe(
            handleAddNewSection(newSectionTitle),
            T.chain(() => T.fromIO(closeModal))
          )
    )
  );

  // Handle document download
  const triggerUserDownload = useCallback(
    (blob: Blob, title: string): IO.IO<void> =>
      () => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${title}.md`; // Adjust the filename and extension as needed
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      },
    []
  );

  const handleDownload = useCallback(
    (id: DocumentID, title: string): T.Task<void> =>
      pipe(
        T.fromIO(triggerLoadingState),
        T.flatMap(() =>
          pipe(
            lifecycleClient.downloadDocumentAsBlob(id),
            TE.fold(
              (error: LifecycleError) =>
                T.fromIO(handleLifecycleErrorIo(error, triggerErrorState)),
              (blob) =>
                pipe(
                  T.fromIO(triggerUserDownload(blob, title)),
                  T.flatMap(() => T.fromIO(triggerSuccessState))
                )
            )
          )
        )
      ),
    [
      triggerLoadingState,
      lifecycleClient,
      handleLifecycleErrorIo,
      triggerSuccessState,
      triggerErrorState,
      triggerUserDownload,
    ]
  );

  return (
    <>
      <View style={styles.buttonContainer}>
        <Text style={{ fontSize: 16, color: "#000" }}>
          <Text style={{ fontWeight: "bold" }}>Note:-</Text> Click to see
          options
        </Text>
        <View style={styles.buttonContainerSecond}>
          <TouchableOpacity style={[styles.button]} onPress={openModal}>
            <Text style={styles.buttonText}>Append New Section</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.actionButton}
            onPress={() => {
              handleDownload(userDocument.id, userDocument!.name)();
            }}
          >
            <MaterialIcons
              name="download"
              size={24}
              style={styles.icon}
              color={"#fff"}
            />
            <Text style={styles.actionButtonText}>Download</Text>
          </TouchableOpacity>
        </View>
      </View>
      <SectionList
        handleDraft={handleDraft}
        handleRefine={navigateToRefineIo}
        handleEdits={navigateToFinalEditsIo}
      />

      <NewSectionModal
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        newSectionTitle={newSectionTitle}
        setNewSectionTitle={setNewSectionTitle}
        sectionTitleError={sectionTitleError}
        handleSubmitNewSection={handleSubmitNewSection}
      />
    </>
  );
};

export default ReviewPage;
