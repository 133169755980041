// @model/clients/PaymentApi.ts

import * as t from 'io-ts';
import { createBrandedDecoder } from '@utils/brandedDecoder';

// Branding for types
export type SubscriptionPlan = 'Monthly' | 'Annual';


// Stripe Publishable Key
export interface StripePublishableKeyBrand {
    readonly StripePublishableKey: unique symbol;
}

export const StripePublishableKey = createBrandedDecoder(
    t.string, // Validate as a regular string
    (s): s is t.Branded<string, StripePublishableKeyBrand> => s.length > 0, // Brand after validation
    'StripePublishableKey'
);
export type StripePublishableKey = t.TypeOf<typeof StripePublishableKey>;

// Stripe Payment Intent Secret
export interface StripePaymentIntentSecretBrand {
    readonly StripePaymentIntentSecret: unique symbol;
}

export const StripeSetupIntentSecret = createBrandedDecoder(
    t.string, // Validate as a regular string
    (s): s is t.Branded<string, StripePaymentIntentSecretBrand> => s.length > 0, // Brand after validation
    'StripeSetupIntentSecret'
);
export type StripeSetupIntentSecret = t.TypeOf<typeof StripeSetupIntentSecret>;

// Response types with io-ts validation
export const GetPublishableKeyResponse = t.type({
    stripe_publishable_key: StripePublishableKey,
});
export type GetPublishableKeyResponse = t.TypeOf<typeof GetPublishableKeyResponse>;

export const SetupIntentResponse = t.type({
    setup_intent_secret: StripeSetupIntentSecret, // Expect plain string and validate/brand it
});
export type SetupIntentResponse = t.TypeOf<typeof SetupIntentResponse>;

// PromoCodeStatus type
export const PromoCodeStatus = t.union([t.literal('Valid'), t.literal('Not Found'), t.literal('Expired'), t.literal('Used')]);
export type PromoCodeStatus = t.TypeOf<typeof PromoCodeStatus>;
export type SubscriptionStatus = 'FreeTrialMode' | 'Paid' | 'PaymentFailed' | 'PaymentNotYetConfigured' | 'Canceled';

// Promo Code
export const PromoCode = t.string;
export type PromoCode = t.TypeOf<typeof PromoCode>;

// PromoCodeRequest type
export const PromoCodeRequest = t.type({
    value: PromoCode,
});
export type PromoCodeRequest = t.TypeOf<typeof PromoCodeRequest>;

// PromoCodeResponse type
export const PromoCodeResponse = t.type({
    promo_status: PromoCodeStatus, // Reusing PromoCodeStatus here
});
export type PromoCodeResponse = t.TypeOf<typeof PromoCodeResponse>;


// DemographicInformation type
export const DemographicInformation = t.type({
    user_industry: t.string,
    user_title: t.string,
    user_intention: t.string,
    user_zipcode: t.string
});
export type DemographicInformation = t.TypeOf<typeof DemographicInformation>;

export const TrialActivationRequest = t.type({
    promo_code: PromoCode,
    demographic_info: DemographicInformation,
});
export type TrialActivationRequest = t.TypeOf<typeof TrialActivationRequest>;

// Define PaymentError type
export type PaymentError =
    | { type: 'NetworkError'; message: string }
    | { type: 'ServerError'; message: string; statusCode: number }
    | { type: 'ValidationError'; message: string }
    | { type: 'UnknownError'; message: string };
