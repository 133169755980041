import React, { useCallback } from "react";
import {RouteProp, useNavigation} from "@react-navigation/native";
import {
    AppRoutes,
    AuthenticationRoutes, PaymentRoutes,
    WorkspaceRoutes,
} from "@navigation/RouteEnums";
import DemographicSurveyForm from "@src/components/demographic/DemographicSurveyForm";
import { usePaymentClient } from "@contexts/PaymentClient.context";
import * as IO from "fp-ts/lib/IO";
import * as T from "fp-ts/lib/Task";
import * as TE from "fp-ts/lib/TaskEither";
import { pipe } from "fp-ts/lib/function";
import { StackNavigationProp } from "@react-navigation/stack";
import {
    AppStackParamList,
    AuthenticationStackParamList, PaymentStackParamList,
    WorkspaceRoutesParamList,
} from "@src/navigation/NavigationRouteParameters";
import { useIoBasedLoadingSetters } from "@src/contexts/Loading.context";
import { CreateDemographicRequest } from "@src/model/clients/LifecycleApi";
import {DemographicInformation, PaymentError} from "@src/model/clients/PaymentApi";
import { handlePaymentErrorIo } from "@src/utils/errorHandlers/paymentErrorHandler";

type NavigationType = StackNavigationProp<
  WorkspaceRoutesParamList,
  keyof WorkspaceRoutesParamList
>;

type DemographicProps = {
  navigation: StackNavigationProp<
    AuthenticationStackParamList,
    typeof AuthenticationRoutes.LOGIN
  >;
  appNavigator: StackNavigationProp<AppStackParamList, typeof AppRoutes.AUTH>;
  route: RouteProp<
      PaymentStackParamList,
      typeof PaymentRoutes.DEMOGRAPHIC_SURVEY
  >;
};

const DemographicSurveyPage: React.FC<DemographicProps> = ({
  appNavigator, route
}) => {
  const { paymentClient } = usePaymentClient();
  const { triggerLoadingState, triggerSuccessState, triggerErrorState } =
    useIoBasedLoadingSetters();

  const { promo_code } = route.params;
  // IO for navigating to DOCUMENT_SELECTION
  const navigateToDocumentSelectionIo: IO.IO<void> = () => {
    appNavigator.navigate(AppRoutes.WORKSPACE, {
      screen: WorkspaceRoutes.HOME,
    });
  };

  const handleSubmit = useCallback(
    (demographicSurvey: DemographicInformation): T.Task<void> => {

     return pipe(
        T.fromIO(triggerLoadingState),
        T.flatMap(() =>
          pipe(
            paymentClient.submitDemographicInformation(
              promo_code, demographicSurvey
            ),
            TE.fold(
              (error: PaymentError) =>
                T.fromIO(handlePaymentErrorIo(error, triggerErrorState)),
              () =>
                pipe(
                  T.fromIO(navigateToDocumentSelectionIo),
                  T.flatMap(() => T.fromIO(triggerSuccessState))
                )
            )
          )
        )
      );
    },
    [paymentClient, triggerLoadingState, triggerSuccessState, triggerErrorState]
  );

  return <DemographicSurveyForm onSubmit={handleSubmit} />;
};

export default DemographicSurveyPage;
