// @services/stubs/StubPaymentClient.ts
import { PaymentClient } from '@services/clients/PaymentClient';
import {
    StripeSetupIntentSecret,
    StripePublishableKey,
    SubscriptionPlan,
    StripePublishableKey as StripePublishableKeyType,
    StripeSetupIntentSecret as StripePaymentIntentSecretType,
    PaymentError,
    PromoCode,
    PromoCodeResponse,
    DemographicInformation,
} from '@model/clients/PaymentApi';
import { TaskEither, right, left } from 'fp-ts/lib/TaskEither';
import { pipe } from 'fp-ts/lib/function';
import { fold } from 'fp-ts/lib/Either';
import { delayTaskEither } from '@utils/delayTaskEither'; // Assuming this helper exists

export class StubPaymentClient implements PaymentClient {
    getPublishableKey(): TaskEither<PaymentError, StripePublishableKey> {
        console.log('StubPaymentClient.getPublishableKey called');

        // Simulate delay and return a stubbed publishable key
        return delayTaskEither(100, pipe(
            StripePublishableKeyType.decode('pk_test_stubbed_key_123'),
            fold(
                () => left({
                    type: 'ValidationError',
                    message: 'Invalid publishable key in stub',
                } as PaymentError),
                right
            )
        ));
    }

    setupIntent(
        subscriptionPlan: SubscriptionPlan
    ): TaskEither<PaymentError, StripeSetupIntentSecret> {
        console.log('StubPaymentClient.setupIntent called with subscription plan:', subscriptionPlan);

        // Simulate delay and return a stubbed payment intent secret
        return delayTaskEither(100, pipe(
            StripePaymentIntentSecretType.decode('pi_test_stubbed_intent_secret_123'),
            fold(
                () => left({
                    type: 'ValidationError',
                    message: 'Invalid payment intent secret in stub',
                } as PaymentError),
                right
            )
        ));
    }

    cancelSubscription(): TaskEither<PaymentError, void> {
        console.log('StubPaymentClient.cancelSubscription called');

        // Simulate delay and return success (void)
        return delayTaskEither(100, right(undefined));
    }

    // New method: setPromoCode
    validatePromoCode(promoCode: PromoCode): TaskEither<PaymentError, PromoCodeResponse> {
        console.log('StubPaymentClient.setPromoCode called with promo code:', promoCode);

        // Simulate delay and return a successful PromoCodeResponse
        const isValidCode = promoCode === 'VALID_PROMO';
        const response: PromoCodeResponse = {
            status: isValidCode ? 'Valid' : 'Invalid',
        };

        return delayTaskEither(100, right(response));
    }

    // Updated method: submitDemographicInformation
    submitDemographicInformation(
        promoCode: PromoCode,
        demographicInfo: DemographicInformation
    ): TaskEither<PaymentError, void> {
        console.log('StubPaymentClient.submitDemographicInformation called with promo code:', promoCode);
        console.log('StubPaymentClient.submitDemographicInformation called with demographic info:', demographicInfo);

        // Simulate delay and return success (void)
        return delayTaskEither(100, right(undefined));
    }
}
