import React, { useCallback } from "react";
import { StackNavigationProp } from "@react-navigation/stack";
import {
  AppRoutes,
  PaymentRoutes,
} from "@navigation/RouteEnums";
import {
  AppStackParamList,
  PaymentStackParamList,
} from "@navigation/NavigationRouteParameters";
import {
    StripeSetupIntentSecret,
    SubscriptionPlan,
    PromoCodeResponse,
    SubscriptionStatus,
    PromoCode, PromoCodeStatus,
} from "@model/clients/PaymentApi";
import { usePaymentClient } from "@contexts/PaymentClient.context";
import { pipe } from "fp-ts/lib/function";
import * as T from "fp-ts/lib/Task"; // Import Task utilities
import * as TE from "fp-ts/lib/TaskEither"; // Import TaskEither utilities
import * as IO from "fp-ts/lib/IO"; // Import IO utilities
import { useIoBasedLoadingSetters } from "@contexts/Loading.context"; // Use IO-based loading context setters
import { handlePaymentErrorIo } from "@utils/errorHandlers/paymentErrorHandler"; // Use IO-based PaymentError handler
import ChooseSubscriptionComponent from "@src/components/payment/ChooseSubscription";
import { RouteProp } from "@react-navigation/native";

type ChoosePackageProps = {
  navigation: StackNavigationProp<
    PaymentStackParamList,
    typeof PaymentRoutes.CHOOSE_SUBSCRIPTION
  >;
  appNavigator: StackNavigationProp<
    AppStackParamList,
    typeof AppRoutes.PAYMENT
  >;
  route: RouteProp<
    PaymentStackParamList,
    typeof PaymentRoutes.CHOOSE_SUBSCRIPTION
  >;
};

const ChooseSubscriptionPage: React.FC<ChoosePackageProps> = ({
  navigation,
  route,
}) => {
  const { paymentClient } = usePaymentClient();
  const { triggerLoadingState, triggerSuccessState, triggerErrorState } =
    useIoBasedLoadingSetters(); // Use IO-based loading context setters
  const { subscription_status } = route.params;

  // IO for navigating to the payment setup page
  const navigateToPaymentSetupIo = useCallback(
    (paymentIntentSecret: StripeSetupIntentSecret): IO.IO<void> =>
      () =>
        navigation.navigate(PaymentRoutes.PAYMENT_SETUP, {
          setup_intent_secret: paymentIntentSecret,
        }),
    [navigation]
  );
    const navigateToDemographicSurveyIo = useCallback(
        (promoCode: PromoCode): IO.IO<void> =>
            () =>
                navigation.navigate(PaymentRoutes.DEMOGRAPHIC_SURVEY, {
                    promo_code: promoCode, // Pass the promo code here
                }),
        [navigation]
    );


  // Task for handling success state and navigating to payment setup page
  const handlePaymentSuccessTask = useCallback(
    (paymentIntentSecret: StripeSetupIntentSecret): T.Task<void> =>
      pipe(
        T.fromIO(triggerSuccessState), // Set success state
        T.flatMap(() => T.fromIO(navigateToPaymentSetupIo(paymentIntentSecret))) // Navigate to payment setup page
      ),
    [triggerSuccessState, navigateToPaymentSetupIo]
  );

    const handlePromoSuccessTask = useCallback(
        (promoCode: PromoCode): IO.IO<void> =>
            pipe(
                triggerSuccessState, // Set success state (pure IO action)
                IO.flatMap(() => navigateToDemographicSurveyIo(promoCode)) // Pass promo code to navigation
            ),
        [triggerSuccessState, navigateToDemographicSurveyIo]
    );


  // Task to handle subscription package selection
  const moveToConfigurePayment = useCallback(
    (subscription: SubscriptionPlan): T.Task<void> =>
      pipe(
        T.fromIO(triggerLoadingState), // Start loading when selecting a package
        T.flatMap(() =>
          pipe(
            paymentClient.setupIntent(subscription),
            TE.fold(
              (error) =>
                T.fromIO(handlePaymentErrorIo(error, triggerErrorState)), // Handle payment error with IO
              (paymentIntentSecret) =>
                handlePaymentSuccessTask(
                  paymentIntentSecret as StripeSetupIntentSecret
                ) // Handle payment success
            )
          )
        )
      ),
    [
      triggerLoadingState,
      paymentClient,
      triggerErrorState,
      handlePaymentSuccessTask,
    ]
  );

  // Updated handlePromoCode function
    const handlePromoCode: (promoCode: PromoCode) => T.Task<PromoCodeStatus> = useCallback(
        (promoCode: PromoCode): T.Task<PromoCodeStatus> =>
            pipe(
                T.fromIO(triggerLoadingState), // Start loading
                T.flatMap(() =>
                    pipe(
                        paymentClient.validatePromoCode(promoCode), // Call to validate promo code
                        TE.fold(
                            // Handle errors during promo code retrieval
                            (paymentError) =>
                                pipe(
                                    T.fromIO(handlePaymentErrorIo(paymentError, triggerErrorState)),
                                    T.map(() => "Not Found" as PromoCodeStatus) // Return a default "Invalid" status on error
                                ),
                            // Process the promo code status on success
                            (response: PromoCodeResponse) =>
                                pipe(
                                    response.promo_status === "Valid"
                                        ? T.fromIO(handlePromoSuccessTask(promoCode)) // Handle success
                                        : T.fromIO(triggerSuccessState), // End loading for other statuses
                                    T.map(() => response.promo_status) // Return the actual status
                                )
                        )
                    )
                )
            ),
        [paymentClient, triggerLoadingState, triggerErrorState, handlePromoSuccessTask]
    );

  return (
    <ChooseSubscriptionComponent
      onPackageSelectionTask={moveToConfigurePayment}
      subscriptionStatus={subscription_status as SubscriptionStatus}
      setPromoCode={handlePromoCode}
    />
  );
};

export default ChooseSubscriptionPage;
