import React from "react";
import { View } from "react-native";
import {
  Button,
  RadioButton,
  Text,
  Title,
  Paragraph,
  Card,
  TextInput,
} from "react-native-paper";
import { PromoCodeStatus, SubscriptionPlan } from "@model/clients/PaymentApi";
import * as T from "fp-ts/lib/Task";
import * as IO from "fp-ts/lib/IO";
import { styles } from "@styles/Subscription.style";
import { SubscriptionStatus } from "@model/clients/AuthenticationApi";
import { useIoState } from "@utils/useIoState";

import { pipe } from "fp-ts/lib/function";

type ChoosePackageComponentProps = {
  onPackageSelectionTask: (packageType: SubscriptionPlan) => T.Task<void>;
  subscriptionStatus: SubscriptionStatus;
  setPromoCode: (code: string) => T.Task<PromoCodeStatus>;
};

const ChooseSubscriptionComponent: React.FC<ChoosePackageComponentProps> = ({
                                                                              onPackageSelectionTask,
                                                                              subscriptionStatus,
                                                                              setPromoCode,
                                                                            }) => {
  const [selectedPackage, setSelectedPackage] = useIoState<SubscriptionPlan>(
      "Monthly"
  );
  const [loading, setLoading] = React.useState(false);
  const [loadingPromo, setLoadingPromoIo] = useIoState(false);
  const [promoCode, setPromoCodeState] = React.useState<string>("");
  const [promoCodeError, setPromoCodeErrorIo] = useIoState<string | null>(
      null
  );

  // Helper function to manage promo code status updates
  const handlePromoCodeStatus: (status: PromoCodeStatus) => (IO.IO<void>) = (
      status: PromoCodeStatus,
  ) => {
    switch (status) {
      case "Valid":
        return setPromoCodeErrorIo(null); // Clear error; navigation occurs in `.page` logic

      case "Expired":
        return setPromoCodeErrorIo("This promo code has expired.");

      case "Not Found":
        return setPromoCodeErrorIo("The promo code entered is invalid, check your spelling and capitalization.");

      default:
        return setPromoCodeErrorIo("An unknown error occurred while applying the promo code.");
    }
  };

  // Main method for submitting promo codes
  const handlePromoCodeSubmit =
    pipe(
        T.fromIO(setLoadingPromoIo(true)), // Start loading state
        T.flatMap(() =>
            promoCode.trim()
                ? setPromoCode(promoCode) // Submit the promo code
                : T.of<PromoCodeStatus>("Not Found") // Fallback for empty input
        ),
        T.flatMap((status) =>
            T.fromIO(
                pipe(
                    setLoadingPromoIo(false), // Stop loading
                    IO.flatMap(() => handlePromoCodeStatus(status) // Delegate to helper
                )
            ))
        )
    );

  const subscriptionHelperText: Record<SubscriptionStatus, string> = {
    PaymentFailed: "There was an issue processing your previous payment, please update your information.",
    PaymentNotYetConfigured: "All packages come with a 30-day free trial, and there's no charge for canceling before the trial expires.",
    FreeTrialMode: "You are currently in free trial mode. Upgrade to a paid plan before the trial expires.",
    Paid: "Thank you for being a valued subscriber! You can update your plan here.",
    Canceled: "Your previous subscription was canceled, but you can restart it by purchasing a plan below.",
  };

  const helperText = subscriptionHelperText[subscriptionStatus] || "";


  const handlePackageSelection = async () => {
    setLoading(true);
    await onPackageSelectionTask(selectedPackage)();
    setLoading(false);
  };

  return (
      <View style={styles.container}>
        <Card style={styles.card}>
          <Title style={styles.title}>Select a Subscription Package</Title>
          <Paragraph>{helperText}</Paragraph>

          <RadioButton.Group
              onValueChange={(value: string) =>
                  setSelectedPackage(value as SubscriptionPlan)()
              }
              value={selectedPackage ?? ""}
          >
            <View style={styles.mainContainerStyle}>
              {["Monthly", "Annual"].map((plan) => (
                  <View key={plan} style={styles.radioButtonContainer}>
                    <RadioButton.Item
                        label={plan}
                        value={plan}
                        status={
                          selectedPackage === plan ? "checked" : "unchecked"
                        }
                        color="#296a6a"
                        style={
                          selectedPackage === plan
                              ? styles.selectedRadioButton
                              : styles.unSelectedRadioButton
                        }
                    />
                    <Paragraph style={styles.planDetails}>
                      {plan === "Monthly"
                          ? "Subscribe monthly for more flexibility. $50/month"
                          : "Pay annually and get 2 months free! $500/year"}
                    </Paragraph>
                  </View>
              ))}
            </View>
          </RadioButton.Group>

          <Text style={styles.textHeading}>
            If you have a promo code, please enter it below. This will allow you to submit a survey instead of a payment method to start your trial.
          </Text>

          {/* Promo Code Input */}
          <TextInput
              label="Enter Promo Code"
              value={promoCode}
              onChangeText={setPromoCodeState}
              style={styles.promoInput}
              error={!!promoCodeError}
          />
          {promoCodeError && (
              <Text style={styles.errorText}>{promoCodeError}</Text>
          )}

          <Button
              mode="contained"
              onPress={handlePromoCodeSubmit}
              disabled={loading || loadingPromo}
              style={styles.button}
          >
            {loadingPromo ? "Applying..." : "Apply Promo Code"}
          </Button>

          {/* Continue to Payment Button */}
          <Button
              mode="contained"
              onPress={handlePackageSelection}
              disabled={!selectedPackage || loading}
              style={styles.button}
          >
            {loading ? "Processing..." : "Continue to Payment"}
          </Button>

          {selectedPackage && (
              <Text style={styles.selectedPackageText}>
                You’ve selected the {selectedPackage} package. All plans include a
                30-day free trial, during which you can cancel without charge.
              </Text>
          )}
          <Text style={styles.selectedPackageText}>
            By proceeding, you authorize billing to begin at the end of the trial
            period. You may cancel anytime through your account settings to avoid
            future charges.
          </Text>
        </Card>
      </View>
  );
};

export default ChooseSubscriptionComponent;
