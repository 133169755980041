// @styles/Subscription.style.ts
import {StyleSheet} from "react-native";

export const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        padding: 16,
    },
    mainContainerStyle: {
        marginTop: 5,
        borderColor: '#d3d3d3',
        borderWidth: 1,
        borderRadius: 8,
        padding: 10,
        backgroundColor: '#f8f8f8',
    },
    card: {
        padding: 16,
        borderRadius: 8,
        elevation: 3,
    },
    title: {
        marginBottom: 8,
    },
    textHeading: {
        marginTop: 20,
        marginBottom: 8,
        paddingLeft: 5
    },
    radioButtonContainer: {
        marginVertical: 8,
        paddingVertical: 8,
        backgroundColor: "#ffffff",
        borderRadius: 8,
        paddingHorizontal: 8,
        marginBottom: 8,
        borderWidth: 1,
        borderColor: '#ddd',
    },
    selectedRadioButton: {
        backgroundColor: "#e7e0ec",
        borderWidth: 1,
        borderRadius: 4,
        padding: 8,
    },
    unSelectedRadioButton: {
        backgroundColor: "#f5f5f5",
        borderColor: "#ececec",
        borderWidth: 1,
        borderRadius: 4,
        padding: 8,
    },
    planDetails: {
        marginLeft: 32,
        marginTop: 8,
        color: "#666",
    },
    button: {
        marginTop: 16,
        backgroundColor: "#296a6a",
    },
    selectedPackageText: {
        marginTop: 16,
        color: "#333",
    },
    promoInput: {
        marginBottom: 8,
    },
    errorText: {
        color: "red",
        fontSize: 12,
    },
    successText: {
        color: "green",
        fontSize: 12,
        marginTop: 4,
    }
});