// @model/clients/LifecycleApi.ts

import * as t from 'io-ts';
import {
    DocumentID,
    DocumentMetadata,
    OutlineSection,
    UserDocument,
} from '@model/documents/core';

// Define request and response types

export const CreateDocumentRequest = t.type({
    outline: t.string,
    style: t.string,
    name: t.string,
});
export type CreateDocumentRequest = t.TypeOf<typeof CreateDocumentRequest>;

export const CreateDemographicRequest = t.type({
    user_title: t.string,
    user_intention: t.string,
});
export type CreateDemographicRequest = t.TypeOf<typeof CreateDemographicRequest>;

export const CreateDocumentResponse = t.type({
    prompting_question: t.string,
    document_id: DocumentID,
});
export type CreateDocumentResponse = t.TypeOf<typeof CreateDocumentResponse>;

export const ListAvailableDocumentsResponse = t.type({
    documents: t.array(DocumentMetadata),
});
export type ListAvailableDocumentsResponse = t.TypeOf<typeof ListAvailableDocumentsResponse>;

export const DocumentFetchResponse = UserDocument;
export type DocumentFetchResponse = t.TypeOf<typeof DocumentFetchResponse>;

export const OutlineResponse = t.type({
    outline: t.array(OutlineSection),
});
export type OutlineResponse = t.TypeOf<typeof OutlineResponse>;

// Define LifecycleError type
export type LifecycleError =
    | { type: 'NetworkError'; message: string }
    | { type: 'ServerError'; message: string; statusCode: number }
    | { type: 'ValidationError'; message: string }
    | { type: 'DownloadError'; message: string }
    | { type: 'UnknownError'; message: string };
